<template>
  <div class="member-view pb-5">
    <div class="view-header mb-4">
      <p class="heading-medium-big text-secondary mb-3">Welcome back, {{ this.$store.getters['getUserFirstName'] }}</p>
      <h2 class="heading-medium-big">Account Details</h2>
      <p class="sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
    </div>

    <validation-observer
      v-if="allDataLoaded"
      ref="member-form"
      tag="form"
      @keydown.enter.prevent>
      <div class="flex mb-4">
        <div class="card width1-3 profile-image full-height relative">
          <template v-if="data.avatar">
            <img
              class="block"
              :src="typeof data.avatar !== 'string' ? filePreview : data.avatar"
              alt="User profile picture" />
          </template>
          <template v-else>
            <img
              class="block img-placeholder"
              :src="require('@/assets/images/image-placeholder.png')"
              alt="User profile picture placeholder" />
          </template>
          <template v-if="!data.avatar">
            <f-file-input
              class="mr-auto"
              id="profilePic"
              isButton
              accept=".jpg, .jpeg, .png"
              :onChange="setProfilePicture"/>
          </template>
          <template v-else>
            <f-button
              theme="close-big"
              class="px-1"
              @click.prevent="removeProfilePicture" />
          </template>
          <div
            v-if="(data.avatar && data.avatar.size) && (data.avatar.size > imageAvatarMaxSize
            || !isImageAvatarSizeValid)"
            class="invalid-feedback px-3 py-3 my-0 mx-0 line-height-base centered-box-absolute img-warning text-center">
            File should not be bigger than {{ imageAvatarMaxWidth }}x{{ imageAvatarMaxHeight }}px, size should not exceed 200KB.
          </div>
        </div>

        <div class="card width2-3 ml-4 card-light">
          <div class="card-body">
            <div class="basic-info">
              <div class="form-group">
                <label for="first-name" class="for-required">
                  First Name:
                </label>

                <validation-provider
                  rules="required"
                  name="first-name"
                  v-slot="{ errors }">
                  <input
                    class="full-width"
                    type="text"
                    v-model="data.first_name"
                    :state="errors.length ? 'invalid' : null"/>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="last-name" class="for-required">
                  Last Name:
                </label>

                <validation-provider
                  rules="required"
                  name="last-name"
                  v-slot="{ errors }">
                  <input
                    class="full-width"
                    type="text"
                    v-model="data.last_name"
                    :state="errors.length ? 'invalid' : null"/>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="email" class="for-required">
                  E-mail:
                </label>

                <validation-provider
                  rules="required"
                  name="email"
                  v-slot="{ errors }">
                  <input
                    class="full-width"
                    type="text"
                    v-model="data.email"
                    :disabled="true"
                    :state="errors.length ? 'invalid' : null"/>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="phone">
                  Phone:
                </label>

                <validation-provider
                  name="phone"
                  v-slot="{ errors }">
                  <input
                    class="full-width"
                    type="tel"
                    v-model="data.phone"
                    :state="errors.length ? 'invalid' : null"/>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="company">
                  Company:
                </label>

                <validation-provider
                  name="company"
                  v-slot="{ errors }">
                  <input
                    class="full-width"
                    type="text"
                    v-model="data.company"
                    :state="errors.length ? 'invalid' : null"/>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="form-group flex">
                <f-button
                  theme="text-secondary-icon"
                  class="mt-auto mx-auto"
                  style="margin-bottom: 8px;"
                  @click.prevent="changePassword">
                  <f-icon
                    class="icon-no-filter mr-1"
                    style="position: relative; top: 1px"
                    name="key" />
                  Change password
                </f-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.is_promotor"
        class="card full-width card-light mb-4">
        <div class="card-body">
          <div class="promoter-info">
            <div class="column">
              <div class="form-group relative">
                <label for="website-link">
                  Website link:
                </label>
                <f-input-wrapper class="full-width is-disabled">
                  <input
                    type="text"
                    disabled="true"
                    v-model="data.website_link"/>
                  <f-button
                    theme="icon"
                    @click.prevent="copyToClipboard(data.website_link)">
                    <f-icon
                      class="icon-no-filter"
                      style="position: relative; top: 1px"
                      name="copy" />
                  </f-button>
                </f-input-wrapper>
                <span
                  :class="{
                    'copy-info': true,
                    'visible': inviteWebsiteLinkCopyTxtVisible
                  }">
                  {{ inviteWebsiteLinkCopyTxt }}
                </span>
              </div>

              <div class="form-group">
                <label for="nickname">
                  Display Name:
                </label>

                <validation-provider
                  name="nickname"
                  v-slot="{ errors }">
                  <input
                    class="full-width"
                    type="text"
                    v-model="data.nickname"
                    :state="errors.length ? 'invalid' : null"/>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="promoter-level">
                  Promoter level:
                </label>

                <validation-provider
                  name="promoter-level"
                  v-slot="{ errors }">
                  <input
                    class="full-width"
                    type="text"
                    v-model="data.promoter_level"
                    :state="errors.length ? 'invalid' : null"/>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </div>

            <div class="column">
              <div class="form-group">
                <label for="introduction">
                  Introduction:
                </label>

                <validation-provider
                  name="introduction"
                  v-slot="{ errors }">
                  <textarea
                    name="introduction"
                    rows="7"
                    class="full-width"
                    v-model="data.introduction"
                    :state="errors.length ? 'invalid' : null" />
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="testimonial">
                  Testimonial:
                </label>

                <validation-provider
                  name="testimonial"
                  v-slot="{ errors }">
                  <textarea
                    name="testimonial"
                    rows="5"
                    class="full-width"
                    v-model="data.testimonial"
                    :state="errors.length ? 'invalid' : null" />
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="about-you">
                  About You:
                </label>

                <validation-provider
                  name="about-you"
                  v-slot="{ errors }">
                  <textarea
                    name="about-you"
                    rows="5"
                    class="full-width"
                    v-model="data.about_you"
                    :state="errors.length ? 'invalid' : null" />
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </div>

            <div class="column">
              <div class="form-group">
                <label for="instagram-link">
                  Instagram Link:
                </label>

                <validation-provider
                  name="instagram_link"
                  rules="valid-url"
                  v-slot="{ errors }">
                  <input
                    class="full-width"
                    type="url"
                    v-model="data.instagram_link"
                    :state="errors.length ? 'invalid' : null"/>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="twitter-link">
                  Twitter Link:
                </label>

                <validation-provider
                  name="twitter-link"
                  rules="valid-url"
                  v-slot="{ errors }">
                  <input
                    class="full-width"
                    type="url"
                    v-model="data.twitter_link"
                    :state="errors.length ? 'invalid' : null"/>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="facebook-link">
                  Facebook Link:
                </label>

                <validation-provider
                  name="facebook-link"
                  rules="valid-url"
                  v-slot="{ errors }">
                  <input
                    class="full-width"
                    type="url"
                    v-model="data.facebook_link"
                    :state="errors.length ? 'invalid' : null"/>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>

              <div class="form-group">
                <label for="linkedin-link">
                  Linkedin Link:
                </label>

                <validation-provider
                  name="linkedin-link"
                  rules="valid-url"
                  v-slot="{ errors }">
                  <input
                    class="full-width"
                    type="url"
                    v-model="data.linkedin_link"
                    :state="errors.length ? 'invalid' : null"/>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="card card-light mb-4">
          <div class="card-body">
            <div class="address-info">
              <div class="column">
                <div class="form-group">
                  <label for="address-1">
                    Address Line 1:
                  </label>

                  <validation-provider
                    name="address-1"
                    v-slot="{ errors }">
                    <input
                      class="full-width"
                      type="text"
                      v-model="data.address_1"
                      :state="errors.length ? 'invalid' : null"/>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="address-2">
                    Address Line 2:
                  </label>

                  <validation-provider
                    name="address-2"
                    v-slot="{ errors }">
                    <input
                      class="full-width"
                      type="text"
                      v-model="data.address_2"
                      :state="errors.length ? 'invalid' : null"/>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="town">
                    Town:
                  </label>

                  <validation-provider
                    name="town"
                    v-slot="{ errors }">
                    <input
                      class="full-width"
                      type="text"
                      v-model="data.town"
                      :state="errors.length ? 'invalid' : null"/>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="region">
                    Region:
                  </label>

                  <validation-provider
                    name="region"
                    v-slot="{ errors }">
                    <input
                      class="full-width"
                      type="text"
                      v-model="data.region"
                      :state="errors.length ? 'invalid' : null"/>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </div>
              <div class="column">
                <div class="form-group">
                  <label for="postcode">
                    Postcode:
                  </label>

                  <validation-provider
                    name="postcode"
                    v-slot="{ errors }">
                    <input
                      class="full-width"
                      type="text"
                      v-model="data.post_code"
                      :state="errors.length ? 'invalid' : null"/>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="country">
                    Country:
                  </label>

                  <validation-provider
                    name="country"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-select': true,
                        'is-invalid': errors.length
                      }">
                      <v-select
                        class="mb-0"
                        :options="allCountries"
                        v-model="data.country"
                        :reduce="(item) => item.id"
                        :searchable="true"
                        :autocomplete="'dummy'"
                        placeholder="Please Select Country"
                        label="name">
                      </v-select>
                    </div>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="language">
                    Language:
                  </label>

                  <validation-provider
                    name="language"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-select': true,
                        'is-invalid': errors.length
                      }">
                      <v-select
                        class="mb-0"
                        :options="allLanguages"
                        v-model="data.language"
                        :reduce="(lang) => lang.id"
                        :searchable="true"
                        placeholder="Please Select Language"
                        label="name">
                      </v-select>
                    </div>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card full-width card-light mb-4">
        <div class="card-body">
          <validation-provider
            rules="required|is-checked"
            name="policy-agreement"
            v-slot="{ errors }">
            <div class="mb-2">
              <f-checkbox
                class="block"
                :disabled="!!data.policy_agreement"
                v-model="data.policy_agreement">
                Policy agreement
              </f-checkbox>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <validation-provider
            name="newsletter"
            v-slot="{ errors }">
            <div class="">
              <f-checkbox
                class="block"
                v-model="data.newsletter">
                Newsletter
              </f-checkbox>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>
        </div>
      </div>
    </validation-observer>

    <f-button
      theme="secondary"
      class="centered-horiz-relative mt-1"
      :big="true"
      :disabled="submitInProgress"
      @click.prevent="validateForm">
      Save
    </f-button>

    <div
      v-if="allDataLoaded && data.is_parent_family_member"
      class="card card-light mb-4 mt-5">
      <div class="card-body">
        <div>
          <h3 class="heading-medium-big mb-3">Your Family Members</h3>

          <div
            v-if="!data.family_links.length"
            class="sub-heading">
            There are no members in Your Family so far.
          </div>

          <div
            v-if="data.family_links.length"
            class="py-2 family-members">
            <div
              v-for="(member, index) of data.family_links"
              :key="'family-member-' + index"
              class="mb-1 family-member">
              <div class="mr-2 text-title-small text-medium-light">{{ member.email_member }}</div>
              <template v-if="member.status">
                <div class="text-success text-title-small text-medium-light">
                  <f-icon
                    name="check-cricle"
                    :fromMap="true"
                    :small="true"/>
                  <span class="ml-1">Invitation accepted</span>
                </div>
                <f-button
                  class="ml-2 no-shrink my-auto"
                  theme="outline-danger"
                  :disabled="actionInProgress"
                  @click.prevent="removeFromFamily(member.id)">
                  Remove from family
                </f-button>
              </template>
              <template v-else>
                <div class="text-warning text-title-small text-medium-light">
                  <f-icon
                    name="warning"
                    :fromMap="true"
                    :small="true"/>
                  <span class="ml-1">Invitation pending</span>
                </div>
                <f-button
                  class="ml-2 no-shrink my-auto"
                  theme="outline-danger"
                  :disabled="actionInProgress"
                  @click.prevent="cancelInvitation(member.id)">
                  Cancel invitation
                </f-button>
              </template>
            </div>
          </div>
        </div>

        <validation-observer
          v-if="data.active_membership && data.can_invite_family"
          ref="member-invite-form"
          tag="form"
          class="mt-4 mb-3 flex flex-align-items-end"
          @keydown.enter.prevent>
          <div class="form-group full-width mb-0">
            <label for="invitee-email">
              Invite a new <em class="text-secondary text-style-normal">Family Member</em>
            </label>
            <validation-provider
              rules="required|email"
              name="invitee-email"
              v-slot="{ errors }">
              <input
                class="full-width"
                type="email"
                v-model="invitee_email"
                placeholder="Enter e-mail adress"/>
              <div class="invalid-feedback absolute">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
          <f-button
            style="margin-bottom: 5px;"
            class="ml-2 no-shrink"
            theme="primary"
            big
            @click.prevent="validateInviteForm">
            Send invitation
          </f-button>
        </validation-observer>
      </div>
    </div>

    <div
      v-if="!allDataLoaded && !loadError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>

    <password-change-popup />
  </div>
</template>
<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import ListUtils from '@/utils/ListUtils.js';
import PasswordChangePopup from '@/components/popups/PasswordChangePopup.vue';

export default {
  name: 'member-account-details',
  components: {
    'password-change-popup': PasswordChangePopup
  },
  computed: {
    allDataLoaded () {
      return this.loaded && this.countriesLoaded && this.languagesLoaded;
    },
    filePreview () {
      if (!this.data.avatar || (typeof this.data.avatar === 'string')) {
        return null;
      }

      return URL.createObjectURL(this.data.avatar);
    }
  },
  data() {
    return {
      data: {
        id: 0,
        about_you: null,
        active: false,
        active_membership: false,
        address_1: null,
        address_2: null,
        avatar: null,
        can_invite_family: false,
        company: null,
        country: null,
        email: null,
        email_verified_at: null,
        facebook_link: null,
        family_links: [],
        first_name: null,
        instagram_link: null,
        introduction: null,
        is_admin: false,
        is_parent_family_member: false,
        is_promotor: false,
        language: null,
        last_name: null,
        linkedin_link: null,
        member_type: 'UT_MEMBER',
        membership_package: null,
        newsletter: false,
        nickname: null,
        parent_id: null,
        phone: '',
        policy_agreement: false,
        post_code: '',
        region: '',
        testimonial: '',
        town: '',
        twitter_link: '',
        website_link: null
      },
      loaded: false,
      loadError: false,
      submitInProgress: false,
      actionInProgress: false,
      invitee_email: null,
      allCountries: [],
      countriesLoaded: false,
      allLanguages: [],
      languagesLoaded: false,
      reloadMemberAvatar: false,
      isImageAvatarSizeValid: false,
      imageAvatarMaxWidth: 800,
      imageAvatarMaxHeight: 600,
      imageAvatarMaxSize: 200000,
      inviteWebsiteLinkCopyTxt: 'Copy link',
      inviteWebsiteLinkCopyTxtVisible: false
    };
  },
  mounted() {
    this.loadItemData();
    this.loadAdditionalData();
  },
  methods: {
    loadItemData () {
      this.loaded = false;

      FormUtils.loadItemData(this, {
        id: null,
        method: 'get',
        endpointBase: '/api/member/profile',
        instanceFields: [
          'id',
          'about_you',
          'active',
          'active_membership',
          'address_1',
          'address_2',
          'avatar',
          'can_invite_family',
          'company',
          'country',
          'email',
          'email_verified_at',
          'facebook_link',
          'family_links',
          'first_name',
          'instagram_link',
          'introduction',
          'is_admin',
          'is_parent_family_member',
          'is_promotor',
          'language',
          'last_name',
          'linkedin_link',
          'member_type',
          'membership_package',
          'newsletter',
          'nickname',
          'parent_id',
          'phone',
          'policy_agreement',
          'post_code',
          'region',
          'testimonial',
          'town',
          'twitter_link',
          'website_link'
        ],
        booleanFields: [
          'active',
          'active_membership',
          'can_invite_family',
          'is_admin',
          'is_promotor',
          'is_parent_family_member',
          'newsletter',
          'policy_agreement'
        ],
        loadedKey: 'loaded',
        successAction: (response) => {
          if (this.reloadMemberAvatar) {
            this.$store.commit('setUserAvatar', response.avatar);
          }
        }
      });
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/get-countries',
        method: 'get',
        outputKey: 'allCountries',
        loadedKey: 'countriesLoaded',
        errorKey: 'loadError',
        noPagination: false
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/get-languages',
        method: 'get',
        outputKey: 'allLanguages',
        loadedKey: 'languagesLoaded',
        errorKey: 'loadError',
        noPagination: false
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['member-form'], this.submitForm);
    },
    submitForm () {
      Vue.set(this, 'submitInProgress', true);
      this.reloadMemberAvatar = false;
      let formDataToSend = this.prepareFormDataToSend();
      let endpointUrl = 'api/member/update';

      let fileParams = [];
      if (formDataToSend.avatar) {
        fileParams.push('file');
      }

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Account saved',
        successText: 'Your account information has been saved',
        errorTitle: 'An error has occurred',
        errorText: 'Modification of your account has failed. Please try again.',
        successAction: () => {
          Vue.set(this, 'submitInProgress', false);
          this.loadItemData();
        },
        failAction: () => {
          Vue.set(this, 'submitInProgress', false);
        }
      }, fileParams);
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        about_you: this.data.about_you,
        address_1: this.data.address_1,
        address_2: this.data.address_2,
        company: this.data.company,
        country: this.data.country,
        facebook_link: this.data.facebook_link,
        first_name: this.data.first_name,
        introduction: this.data.introduction,
        instagram_link: this.data.instagram_link,
        language: this.data.language,
        last_name: this.data.last_name,
        linkedin_link: this.data.linkedin_link,
        newsletter: this.data.newsletter,
        nickname: this.data.nickname,
        phone: this.data.phone,
        policy_agreement: +this.data.policy_agreement,
        post_code: this.data.post_code,
        region: this.data.region,
        testimonial: this.data.testimonial,
        town: this.data.town,
        twitter_link: this.data.twitter_link,
      };

      if (this.data.avatar === null || (this.data.avatar && typeof this.data.avatar !== 'string' && this.data.avatar.size && this.data.avatar.size <= 200000)) {
        formDataToSend.avatar = this.data.avatar;
        this.reloadMemberAvatar = true;
      }

      return formDataToSend;
    },
    changePassword () {
      this.$bus.$emit('password-change-popup-show');
    },
    setProfilePicture (event) {
      Vue.set(this.data, 'avatar', event.target.files[0]);

      this.checkNewImageSize(event.target.files[0]);
    },
    checkNewImageSize (file) {
      let img = new Image();

      let that = this;
      img.onload = function() {
        Vue.set(that, 'isImageAvatarSizeValid', !!((this.width <= that.imageAvatarMaxWidth) && (this.height <= that.imageAvatarMaxHeight)));
      };
      img.src = URL.createObjectURL(file);
    },
    removeProfilePicture () {
      Vue.set(this.data, 'avatar', null);
    },
    validateInviteForm () {
      FormUtils.validate(this.$refs['member-invite-form'], this.sendInvitation);
    },
    sendInvitation () {
      let formDataToSend = { email: this.invitee_email };
      let endpointUrl = '/api/member/send-invitation';

      FormUtils.submitWithConfirm(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Invitation sent',
        successText: 'An invitation to Your FunFit Family has been sent.',
        confirmTitle: 'Are you sure you want to send invitation?',
        confirmText: 'Please confirm if you want to invite ' + this.invitee_email + ' to your FunFit Family account.',
        confirmButtonText: 'Yes, invite',
        confirmButtonColor: '#0BBB66',
        errorTitle: 'An error has occurred',
        errorText: 'Sending invitation failed. Please try again.',
        successAction: () => {
          this.invitee_email = null;
          this.loadItemData();
        }
      });
    },
    removeFromFamily (ID) {
      this.actionInProgress = true;

      ListUtils.removeItem(this, {
        endpointBase: '/api/member/remove-child/',
        id: ID,
        successTitle: 'User removed from family',
        successText: 'User is no longer a member of Your Family Plan',
        confirmTitle: 'Are you sure you want to remove this user from Your FunFit family?',
        confirmText: 'This operation cannot be undone',
        confirmButtonColor: '#E0211B',
        errorTitle: 'An error has occurred',
        errorText: 'User removal from family failed. Please try again.',
        successAction: () => {
          this.actionInProgress = false;
          this.loadItemData();
        },
        cancelAction: () => {
          this.actionInProgress = false;
        },
        failAction: () => {
          this.actionInProgress = false;
        }
      });
    },
    cancelInvitation (ID) {
      this.actionInProgress = true;

      ListUtils.removeItem(this, {
        endpointBase: '/api/member/cancel-invitation/',
        id: ID,
        successTitle: 'Invitation cancelled',
        successText: 'Invitation has been revoked',
        confirmTitle: 'Are you sure you want to cancel this invitation?',
        confirmText: 'This operation cannot be undone',
        confirmButtonColor: '#E0211B',
        errorTitle: 'An error has occurred',
        errorText: 'Cancelling invitation failed. Please try again.',
        successAction: () => {
          this.actionInProgress = false;
          this.loadItemData();
        },
        cancelAction: () => {
          this.actionInProgress = false;
        },
        failAction: () => {
          this.actionInProgress = false;
        }
      });
    },
    copyToClipboard (textToCopy) {
      try {
        let el = document.createElement('textarea');
        el.value = textToCopy;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        Vue.set(this, 'inviteWebsiteLinkCopyTxt', 'Link copied');
        Vue.set(this, 'inviteWebsiteLinkCopyTxtVisible', true);

        setTimeout(() => {
          Vue.set(this, 'inviteWebsiteLinkCopyTxtVisible', false);
          Vue.set(this, 'inviteWebsiteLinkCopyTxt', 'Copy link');
        }, 2000);
      } catch (error) {
        console.error(error);
        Vue.set(this, 'inviteWebsiteLinkCopyTxt', 'Copy error');
        Vue.set(this, 'inviteWebsiteLinkCopyTxtVisible', true);

        setTimeout(() => {
          Vue.set(this, 'inviteWebsiteLinkCopyTxtVisible', false);
          Vue.set(this, 'inviteWebsiteLinkCopyTxt', 'Copy link');
        }, 2000);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.basic-info,
.promoter-info,
.address-info  {
  display: grid;
  grid-column-gap: 30px;
  width: 100%;
}
.basic-info {
  grid-template-columns: 1fr 1fr;
}

.promoter-info {
  grid-template-columns: 1fr 1.4fr 1fr;
}

.address-info {
  grid-template-columns: 1.2fr 1fr;
}

.profile-image {
  background: $color-border-dark;
  overflow: hidden;
  position: relative;

  img {
    height: 100%;
    margin: auto;
    object-fit: cover;
    width: 100%;

    &.img-placeholder {
      height: auto;
      max-width: 90px;
    }
  }

  .f-file-input-wrapper,
  .f-btn-close-big {
    position: absolute;
    right: 28px;
    top: 28px;
  }
}

.img-warning {
  background: rgba(238, 238, 238, 0.75);
  border-radius: 9px;
  margin: 0;
  width: 80%;
}
</style>
