<template>
  <f-modal
    ref="password-change-popup-modal"
    :class="{ 'is-visible': isVisible }"
    @close="handleClose">
    <f-modal-header>
      <h3 class="heading-medium">
        Change password
      </h3>
    </f-modal-header>

    <f-modal-body>
      <validation-observer
        ref="password-change-popup-form"
        tag="form"
        @keydown.enter.prevent>

        <div class="form-group">
          <label for="old-password">
            Current Password:
          </label>

          <validation-provider
            rules="required|min:8|max:50"
            name="old-password"
            v-slot="{ errors }">
            <input
              class="full-width"
              type="password"
              :state="errors.length ? 'invalid' : null"
              v-model="data.old_password" />
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="password">
            New Password:
          </label>

          <validation-provider
            rules="required|min:8|max:50"
            name="password"
            v-slot="{ errors }">
            <input
              class="full-width"
              type="password"
              :state="errors.length ? 'invalid' : null"
              v-model="data.new_password" />
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

        <div class="form-group">
          <label for="password-confirmation">
            Confirm Password:
          </label>

          <validation-provider
            rules="required|min:8|max:50|password:password"
            name="password-confirmation"
            v-slot="{ errors }">
            <input
              class="full-width"
              type="password"
              :state="errors.length ? 'invalid' : null"
              v-model="data.new_password_confirmation" />
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>

      </validation-observer>

    </f-modal-body>
    <f-modal-footer>
      <div class="flex flex-justify-content-between">
        <f-button
          class="ml-0 btn btn-danger"
          theme="danger"
          @click.prevent="handleClose">
          Cancel
        </f-button>
        <f-button
          class="ml-auto btn btn-primary"
          theme="primary"
          :disabled="submitInProgress"
          @click.prevent="validateForm">
          <div
            v-if="submitInProgress"
            class="loading-spinner"
            role="status">
          </div>
          Confirm new password
        </f-button>
      </div>
    </f-modal-footer>
  </f-modal>
</template>
<script>
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'password-change-popup',
  computed: {
  },
  data() {
    return {
      data: {
        old_password: '',
        new_password: '',
        new_password_confirmation: ''
      },
      isVisible: false,
      editMode: false,
      submitInProgress: false
    };
  },
  mounted() {
    this.$bus.$on('password-change-popup-show', this.openPopup);
  },
  methods: {
    handleClose() {
      document.body.classList.remove('no-scroll');
      this.isVisible = false;
    },
    openPopup() {
      this.data.old_password = '';
      this.data.new_password = '';
      this.data.new_password_confirmation = '';

      setTimeout(() => {
        this.isVisible = true;
        this.submitInProgress = false;
        document.body.classList.add('no-scroll');

        if (this.$refs['password-change-popup-form']) {
          this.$refs['password-change-popup-form'].reset();
        }
      }, 0);
    },
    validateForm () {
      FormUtils.validate(this.$refs['password-change-popup-form'], this.submitForm);
    },
    submitForm () {
      // let formDataToSend = this.prepareFormDataToSend();
      // let endpointUrl = '/api/member/password-change';

      FormUtils.submit(this, {
        endpoint: '/api/member/password-change',
        formData: this.data,
        successTitle: 'Password changed',
        successText: 'New password has been saved',
        errorTitle: 'An error has occurred',
        errorText: 'Password change has failed. Please try again.',
        successAction: () => {
          this.handleClose();
        }
      });
    },
    // prepareFormDataToSend () {
    //   return {
    //     old_password: this.data.name
    //   };
    // },
  },
  beforeDestroy () {
    this.$bus.$on('password-change-popup-show', this.openPopup);
  }
}
</script>
